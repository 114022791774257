import React from 'react';
import logo from '../logo.svg';
import { NavLink  } from 'react-router-dom';

const Navbar = () => {
  return (
    <header>
        <div className="surecust-header-box">
          <div className="surecust-container">
            <div className="surecust-header-inner">
              <div className="surecust-logo-box">
                <div className="surecust-logo">
                  <img src={logo} alt="SureCust" width="150" heigh="40"/>
                  <span className="logo-hover-tagline">Precision Delivered</span>
                </div>
              </div>
              <div className="surecust-navigation-list">
                  <ul className="surecust-menu-link-list">
                      <li className="surecust-menu-link-item">
                        <NavLink 
                          to="/"
                          className="surecust-menu-link"
                        >
                          Build for Shopify
                        </NavLink>
                      </li>
                      <li className="surecust-menu-link-item">
                        <NavLink
                          to="/approvecustomer"
                          className="surecust-menu-link"
                        >
                          SureCust Forms Builder, Locks
                        </NavLink>
                      </li>
                  </ul>
              </div>
              <div className="surecust-navigation-actions">
                  <a
                    href="mailto:approvemate@gmail.com"
                    className="surecust-action-link"
                  >
                    Contact now
                  </a>
              </div>
            </div>
          </div>
        </div>
      </header>
  );
};

export default Navbar;