// pages/ApproveCustomer.js
import React from 'react';
import { NavLink  } from 'react-router-dom';
import Accordion from '../components/Accordion';

import onlineStore from '../img/approve/onlinestore.jpg';
import themeCustomize from '../img/approve/themecustomize.jpg';
import customerRegister from '../img/approve/customerregister.jpg';
import addSection from '../img/approve/addsection.jpg';
import surecustRegister from '../img/approve/surecustregister.jpg';
import createAccount from '../img/approve/createaccount.jpg';
import createAccountSetting from '../img/approve/createaccountsetting.jpg';
import disableRegister from '../img/approve/disableregister.jpg';
import surecustWholesale from '../img/approve/surecustwholesale.jpg';
import createWholesale from '../img/approve/createwholesale.jpg';
import createWholesaleSetting from '../img/approve/createwholesalesetting.jpg';
import disableRegister2 from '../img/approve/disableregister2.jpg';
import editWholesaleFields from '../img/approve/editwholesalefields.jpg';
import createForm from '../img/approve/createform.jpg';
import wholesaleRegistration from '../img/approve/wholesaleregistration.jpg';
import saveCustomForm from '../img/approve/savecustomform.jpg';
import copyFormId from '../img/approve/copyformid.jpg';
import sucreCustcustomForm from '../img/approve/sucrecustcustomform.jpg';
import clickCustomForm from '../img/approve/clickcustomform.jpg';
import copyCustomFormid from '../img/approve/copycustomformid.jpg';
import contactForm from '../img/approve/contactform.jpg';
import saveContactForm from '../img/approve/savecontactform.jpg';
import copyFormidc from '../img/approve/copyformidc.jpg';
import contactPageTemplate from '../img/approve/contactpagetemplate.jpg';
import contactAddSection from '../img/approve/contactaddsection.jpg';
import disableContactform from '../img/approve/disablecontactform.jpg';
import createProductForm from '../img/approve/createproductform.jpg';
import saveProductForm from '../img/approve/saveproductform.jpg';
import copyProductForm from '../img/approve/copyproductform.jpg';
import productAddSection from '../img/approve/productaddsection.jpg';
import productCustomForm from '../img/approve/productcustomform.jpg';
import productClickform from '../img/approve/productclickform.jpg';
import copyPasteProduct from '../img/approve/copypasteproduct.jpg';
import productPageTemplate from '../img/approve/productpagetemplate.jpg';
import posCreateForm from '../img/approve/poscreateform.jpg';
import savePosForm from '../img/approve/saveposform.jpg';
import openPos from '../img/approve/openpos.jpg';
import clickOnapp from '../img/approve/clickonapp.jpg';
import selectSurecust from '../img/approve/selectsurecust.jpg';
import addWebsite from '../img/approve/addwebsite.jpg';
import saveWebsiteTile from '../img/approve/savewebsitetile.jpg';

const ApproveCustomer = () => {
  return (
    <section>
      <div className='breadcrumb-section'>
          <div className="surecust-container">
              <div className="surecust-navigation-list">
                  <ul className="surecust-menu-link-list">
                      <li className="surecust-menu-link-item">
                        <a 
                          href="https://apps.shopify.com/approve-mate"
                          className="surecust-menu-link"
                          target='_blank'
                        >
                          Install
                        </a>
                      </li>
                      <li className="surecust-menu-link-item">
                        <NavLink
                          to="/approvecustomer/quicksetup"
                          className="surecust-menu-link"
                        >
                          Advance setup guide
                        </NavLink>
                      </li>
                      <li className="surecust-menu-link-item">
                        <NavLink
                          to="/approvecustomer/videos"
                          className="surecust-menu-link"
                        >
                          Video tutorials
                        </NavLink>
                      </li>
                  </ul>
              </div>
          </div>
      </div>
      <div className='accordion-section'>
        <div className="surecust-container">
          <div className="accordion-item-box">
            <Accordion title="Set up the Default Customer registration form.">
              <div className='accordion-item-box-inner'>
                <div className="Polaris-Box">
                  <ul className="Polaris-List Polaris-List--spacingLoose">
                    <li className="Polaris-List__Item">
                      <p className="Polaris-Text--root Polaris-Text--bodyMd">
                        Go to <b>Online Store &gt; Themes &gt; Customize: </b>
                      </p>
                    </li>
                  </ul>
                  <div className="Polaris-Box">
                    <div className="sureCustImage">
                      <div className="Polaris-Box">
                        <img alt="SureCust" loading="lazy" src={onlineStore}/>
                      </div>
                    </div>
                  </div>
                  <ul className="Polaris-List Polaris-List--spacingLoose">
                    <li className="Polaris-List__Item">
                      <p className="Polaris-Text--root Polaris-Text--bodyMd">
                        Now you can see Theme customize is open and you need to select register page from Legacy customer accounts in page selector
                      </p>
                    </li>
                  </ul>
                  <ul className="Polaris-List Polaris-List--spacingLoose">
                    <li className="Polaris-List__Item">
                      <p className="Polaris-Text--root Polaris-Text--bodyMd">
                        Select <b>Legacy customer accounts:</b>
                      </p>
                    </li>
                  </ul>
                  <div className="Polaris-Box">
                    <div className="sureCustImage">
                      <div className="Polaris-Box">
                        <img alt="SureCust" loading="lazy" src={themeCustomize}/>
                      </div>
                    </div>
                  </div>
                  <ul className="Polaris-List Polaris-List--spacingLoose">
                    <li className="Polaris-List__Item">
                      <p className="Polaris-Text--root Polaris-Text--bodyMd">
                        Select <b>Customer register:</b>
                      </p>
                    </li>
                  </ul>
                  <div className="Polaris-Box">
                    <div className="sureCustImage">
                      <div className="Polaris-Box">
                        <img alt="SureCust" loading="lazy" src={customerRegister} />
                      </div>
                    </div>
                  </div>
                  <ul className="Polaris-List Polaris-List--spacingLoose">
                    <li className="Polaris-List__Item">
                      <p className="Polaris-Text--root Polaris-Text--bodyMd">
                        Now you need to click on <b>Add Section</b> in <b>Template</b>
                      </p>
                    </li>
                  </ul>
                  <div className="Polaris-Box">
                    <div className="sureCustImage">
                      <div className="Polaris-Box">
                        <img alt="SureCust" loading="lazy" src={addSection} />
                      </div>
                    </div>
                  </div>
                  <ul className="Polaris-List Polaris-List--spacingLoose">
                    <li className="Polaris-List__Item">
                      <p className="Polaris-Text--root Polaris-Text--bodyMd">
                        Select <b>Apps &gt; SureCust Register</b>
                      </p>
                    </li>
                  </ul>
                  <div className="Polaris-Box">
                    <div className="sureCustImage">
                      <div className="Polaris-Box">
                        <img alt="SureCust" loading="lazy" src={surecustRegister} />
                      </div>
                    </div>
                  </div>
                  <ul className="Polaris-List Polaris-List--spacingLoose">
                    <li className="Polaris-List__Item">
                      <p className="Polaris-Text--root Polaris-Text--bodyMd">
                        Now you need to click on <b>Create account</b>
                      </p>
                    </li>
                  </ul>
                  <div className="Polaris-Box">
                    <div className="sureCustImage">
                      <div className="Polaris-Box">
                        <img alt="SureCust" loading="lazy" src={createAccount} />
                      </div>
                    </div>
                  </div>
                  <ul className="Polaris-List Polaris-List--spacingLoose">
                    <li className="Polaris-List__Item">
                      <p className="Polaris-Text--root Polaris-Text--bodyMd">
                        Here you can edit <b>form title, subtitle, labels</b> and <b>etc.</b>
                      </p>
                    </li>
                  </ul>
                  <div className="Polaris-Box">
                    <div className="sureCustImage">
                      <div className="Polaris-Box">
                        <img alt="SureCust" loading="lazy" src={createAccountSetting} />
                      </div>
                    </div>
                  </div>
                  <ul className="Polaris-List Polaris-List--spacingLoose">
                    <li className="Polaris-List__Item">
                      <p className="Polaris-Text--root Polaris-Text--bodyMd">
                        Finally, you can <b>disable your old form</b> and save customization.
                      </p>
                    </li>
                  </ul>
                  <div className="Polaris-Box">
                    <div className="sureCustImage">
                      <div className="Polaris-Box">
                        <img alt="SureCust" loading="lazy" src={disableRegister} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Accordion>
            <Accordion title="Set up the Default Wholesale application form.">
              <div className='accordion-item-box-inner'>
                <div className="Polaris-Box">
                <ul className="Polaris-List Polaris-List--spacingLoose">
                    <li className="Polaris-List__Item">
                      <p className="Polaris-Text--root Polaris-Text--bodyMd">
                        Go to <b>Online Store &gt; Themes &gt; Customize: </b>
                      </p>
                    </li>
                  </ul>
                  <div className="Polaris-Box">
                    <div className="sureCustImage">
                      <div className="Polaris-Box">
                        <img alt="SureCust" loading="lazy" src={onlineStore}/>
                      </div>
                    </div>
                  </div>
                  <ul className="Polaris-List Polaris-List--spacingLoose">
                    <li className="Polaris-List__Item">
                      <p className="Polaris-Text--root Polaris-Text--bodyMd">
                        Now you can see Theme customize is open and you need to select register page from Legacy customer accounts in page selector
                      </p>
                    </li>
                  </ul>
                  <ul className="Polaris-List Polaris-List--spacingLoose">
                    <li className="Polaris-List__Item">
                      <p className="Polaris-Text--root Polaris-Text--bodyMd">
                        Select <b>Legacy customer accounts:</b>
                      </p>
                    </li>
                  </ul>
                  <div className="Polaris-Box">
                    <div className="sureCustImage">
                      <div className="Polaris-Box">
                        <img alt="SureCust" loading="lazy" src={themeCustomize}/>
                      </div>
                    </div>
                  </div>
                  <ul className="Polaris-List Polaris-List--spacingLoose">
                    <li className="Polaris-List__Item">
                      <p className="Polaris-Text--root Polaris-Text--bodyMd">
                        Select <b>Customer register:</b>
                      </p>
                    </li>
                  </ul>
                  <div className="Polaris-Box">
                    <div className="sureCustImage">
                      <div className="Polaris-Box">
                        <img alt="SureCust" loading="lazy" src={customerRegister} />
                      </div>
                    </div>
                  </div>

                  <ul className="Polaris-List Polaris-List--spacingLoose">
                    <li className="Polaris-List__Item">
                      <p className="Polaris-Text--root Polaris-Text--bodyMd">
                        Now you need to click on <b>Add Section</b> in <b>Template</b>
                      </p>
                    </li>
                  </ul>

                  <div className="Polaris-Box">
                    <div className="sureCustImage">
                      <div className="Polaris-Box">
                        <img alt="SureCust" loading="lazy" src={addSection} />
                      </div>
                    </div>
                  </div>

                  <ul className="Polaris-List Polaris-List--spacingLoose">
                    <li className="Polaris-List__Item">
                      <p className="Polaris-Text--root Polaris-Text--bodyMd">
                        Select <b>Apps &gt; SureCust Wholesale Form</b>
                      </p>
                    </li>
                  </ul>

                  <div className="Polaris-Box">
                    <div className="sureCustImage">
                      <div className="Polaris-Box">
                        <img alt="SureCust" loading="lazy" src={surecustWholesale} />
                      </div>
                    </div>
                  </div>

                  <ul className="Polaris-List Polaris-List--spacingLoose">
                    <li className="Polaris-List__Item">
                      <p className="Polaris-Text--root Polaris-Text--bodyMd">
                        Now you need to click on <b>Create Wholesale account</b>
                      </p>
                    </li>
                  </ul>

                  <div className="Polaris-Box">
                    <div className="sureCustImage">
                      <div className="Polaris-Box">
                        <img alt="SureCust" loading="lazy" src={createWholesale} />
                      </div>
                    </div>
                  </div>

                  <ul className="Polaris-List Polaris-List--spacingLoose">
                    <li className="Polaris-List__Item">
                      <p className="Polaris-Text--root Polaris-Text--bodyMd">
                        Here you can edit <b>form title, subtitle, success message</b> and <b>etc.</b>
                      </p>
                    </li>
                  </ul>

                  <div className="Polaris-Box">
                    <div className="sureCustImage">
                      <div className="Polaris-Box">
                        <img alt="SureCust" loading="lazy" src={createWholesaleSetting} />
                      </div>
                    </div>
                  </div>

                  <ul className="Polaris-List Polaris-List--spacingLoose">
                    <li className="Polaris-List__Item">
                      <p className="Polaris-Text--root Polaris-Text--bodyMd">
                        Now, you can <b>disable your old form</b> and save customization.
                      </p>
                    </li>
                  </ul>

                  <div className="Polaris-Box">
                    <div className="sureCustImage">
                      <div className="Polaris-Box">
                        <img alt="SureCust" loading="lazy" src={disableRegister2} />
                      </div>
                    </div>
                  </div>

                  <ul className="Polaris-List Polaris-List--spacingLoose">
                    <li className="Polaris-List__Item">
                      <p className="Polaris-Text--root Polaris-Text--bodyMd">
                        For editing field labels, placeholders, and sizes, go to: <b>SureCust App &gt; Settings &gt; Default wholesale form fields</b>
                      </p>
                    </li>
                  </ul>
                  <div className="Polaris-Box">
                    <div className="sureCustImage">
                      <div className="Polaris-Box">
                        <img alt="SureCust" loading="lazy" src={editWholesaleFields} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Accordion>
            <Accordion title="Set up the #Custom Wholesale application form.">
              <div className='accordion-item-box-inner'>
                <div className="Polaris-Box">
                  <ul className="Polaris-List Polaris-List--spacingLoose">
                    <li className="Polaris-List__Item">
                      <p className="Polaris-Text--root Polaris-Text--bodyMd">
                        Go to: <b>SureCust App &gt; Custom forms &gt; </b> and click on <b>Create form</b>
                      </p>
                    </li>
                  </ul>
                  <div className="Polaris-Box">
                    <div className="sureCustImage">
                      <div className="Polaris-Box">
                        <img alt="SureCust" loading="lazy" src={createForm} />
                      </div>
                    </div>
                  </div>
                  <ul className="Polaris-List Polaris-List--spacingLoose">
                    <li className="Polaris-List__Item">
                      <p className="Polaris-Text--root Polaris-Text--bodyMd">
                        Now create <b>Wholesale registration form</b>
                      </p>
                    </li>
                  </ul>
                  <div className="Polaris-Box">
                    <div className="sureCustImage">
                      <div className="Polaris-Box">
                        <img alt="SureCust" loading="lazy" src={wholesaleRegistration} />
                      </div>
                    </div>
                  </div>
                  <ul className="Polaris-List Polaris-List--spacingLoose">
                    <li className="Polaris-List__Item">
                      <p className="Polaris-Text--root Polaris-Text--bodyMd">
                        After creating custom form click on <b>Save</b>
                      </p>
                    </li>
                  </ul>
                  <div className="Polaris-Box">
                    <div className="sureCustImage">
                      <div className="Polaris-Box">
                        <img alt="SureCust" loading="lazy" src={saveCustomForm} />
                      </div>
                    </div>
                  </div>
                  <ul className="Polaris-List Polaris-List--spacingLoose">
                    <li className="Polaris-List__Item">
                      <p className="Polaris-Text--root Polaris-Text--bodyMd">
                        After save you need to copy the <b>Form ID.</b>
                      </p>
                    </li>
                  </ul>
                  <div className="Polaris-Box">
                    <div className="sureCustImage">
                      <div className="Polaris-Box">
                        <img alt="SureCust" loading="lazy" src={copyFormId} />
                      </div>
                    </div>
                  </div>
                  <ul className="Polaris-List Polaris-List--spacingLoose">
                    <li className="Polaris-List__Item">
                      <p className="Polaris-Text--root Polaris-Text--bodyMd">
                        Go to <b>Online Store &gt; Themes &gt; Customize: </b>
                      </p>
                    </li>
                  </ul>
                  <div className="Polaris-Box">
                    <div className="sureCustImage">
                      <div className="Polaris-Box">
                        <img alt="SureCust" loading="lazy" src={onlineStore}/>
                      </div>
                    </div>
                  </div>
                  <ul className="Polaris-List Polaris-List--spacingLoose">
                    <li className="Polaris-List__Item">
                      <p className="Polaris-Text--root Polaris-Text--bodyMd">
                        Now you can see Theme customize is open and you need to select register page from Legacy customer accounts in page selector
                      </p>
                    </li>
                  </ul>
                  <ul className="Polaris-List Polaris-List--spacingLoose">
                    <li className="Polaris-List__Item">
                      <p className="Polaris-Text--root Polaris-Text--bodyMd">
                        Select <b>Legacy customer accounts:</b>
                      </p>
                    </li>
                  </ul>
                  <div className="Polaris-Box">
                    <div className="sureCustImage">
                      <div className="Polaris-Box">
                        <img alt="SureCust" loading="lazy" src={themeCustomize}/>
                      </div>
                    </div>
                  </div>
                  <ul className="Polaris-List Polaris-List--spacingLoose">
                    <li className="Polaris-List__Item">
                      <p className="Polaris-Text--root Polaris-Text--bodyMd">
                        Select <b>Customer register:</b>
                      </p>
                    </li>
                  </ul>
                  <div className="Polaris-Box">
                    <div className="sureCustImage">
                      <div className="Polaris-Box">
                        <img alt="SureCust" loading="lazy" src={customerRegister} />
                      </div>
                    </div>
                  </div>
                  <ul className="Polaris-List Polaris-List--spacingLoose">
                    <li className="Polaris-List__Item">
                      <p className="Polaris-Text--root Polaris-Text--bodyMd">
                        Now you need to click on <b>Add Section</b> in <b>Template</b>
                      </p>
                    </li>
                  </ul>
                  <div className="Polaris-Box">
                    <div className="sureCustImage">
                      <div className="Polaris-Box">
                        <img alt="SureCust" loading="lazy" src={addSection} />
                      </div>
                    </div>
                  </div>
                  <ul className="Polaris-List Polaris-List--spacingLoose">
                    <li className="Polaris-List__Item">
                      <p className="Polaris-Text--root Polaris-Text--bodyMd">
                        Select <b>Apps &gt; SureCust Custom Form</b>
                      </p>
                    </li>
                  </ul>
                  <div className="Polaris-Box">
                    <div className="sureCustImage">
                      <div className="Polaris-Box">
                        <img alt="SureCust" loading="lazy" src={sucreCustcustomForm} />
                      </div>
                    </div>
                  </div>
                  <ul className="Polaris-List Polaris-List--spacingLoose">
                    <li className="Polaris-List__Item">
                      <p className="Polaris-Text--root Polaris-Text--bodyMd">
                        Now you need to click on <b>Custom Form</b>
                      </p>
                    </li>
                  </ul>
                  <div className="Polaris-Box">
                    <div className="sureCustImage">
                      <div className="Polaris-Box">
                        <img alt="SureCust" loading="lazy" src={clickCustomForm} />
                      </div>
                    </div>
                  </div>
                  <ul className="Polaris-List Polaris-List--spacingLoose">
                    <li className="Polaris-List__Item">
                      <p className="Polaris-Text--root Polaris-Text--bodyMd">
                        Here you need to add <b>Form ID</b> that you copied in previous steps.
                      </p>
                    </li>
                  </ul>
                  <div className="Polaris-Box">
                    <div className="sureCustImage">
                      <div className="Polaris-Box">
                        <img alt="SureCust" loading="lazy" src={copyCustomFormid} />
                      </div>
                    </div>
                  </div>
                  <ul className="Polaris-List Polaris-List--spacingLoose">
                    <li className="Polaris-List__Item">
                      <p className="Polaris-Text--root Polaris-Text--bodyMd">
                        Finally, you can <b>disable your old form</b> and save customization.
                      </p>
                    </li>
                  </ul>
                  <div className="Polaris-Box">
                    <div className="sureCustImage">
                      <div className="Polaris-Box">
                        <img alt="SureCust" loading="lazy" src={disableRegister2} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Accordion>
            <Accordion title="Set up the #Custom Contact form.">
              <div className='accordion-item-box-inner'>
                <div className="Polaris-Box">
                  <ul className="Polaris-List Polaris-List--spacingLoose">
                    <li className="Polaris-List__Item">
                      <p className="Polaris-Text--root Polaris-Text--bodyMd">
                        Go to: <b>SureCust App &gt; Custom forms &gt; </b> and click on <b>Create form</b>
                      </p>
                    </li>
                  </ul>
                  <div className="Polaris-Box">
                    <div className="sureCustImage">
                      <div className="Polaris-Box">
                        <img alt="SureCust" loading="lazy" src={createForm} />
                      </div>
                    </div>
                  </div>
                  <ul className="Polaris-List Polaris-List--spacingLoose">
                    <li className="Polaris-List__Item">
                      <p className="Polaris-Text--root Polaris-Text--bodyMd">
                        Now create <b>Contact form</b>
                      </p>
                    </li>
                  </ul>
                  <div className="Polaris-Box">
                    <div className="sureCustImage">
                      <div className="Polaris-Box">
                        <img alt="SureCust" loading="lazy" src={contactForm} />
                      </div>
                    </div>
                  </div>
                  <ul className="Polaris-List Polaris-List--spacingLoose">
                    <li className="Polaris-List__Item">
                      <p className="Polaris-Text--root Polaris-Text--bodyMd">
                        After creating custom form click on <b>Save</b>
                      </p>
                    </li>
                  </ul>
                  <div className="Polaris-Box">
                    <div className="sureCustImage">
                      <div className="Polaris-Box">
                        <img alt="SureCust" loading="lazy" src={saveContactForm} />
                      </div>
                    </div>
                  </div>
                  <ul className="Polaris-List Polaris-List--spacingLoose">
                    <li className="Polaris-List__Item">
                      <p className="Polaris-Text--root Polaris-Text--bodyMd">
                        After save you need to copy the <b>Form ID.</b>
                      </p>
                    </li>
                  </ul>
                  <div className="Polaris-Box">
                    <div className="sureCustImage">
                      <div className="Polaris-Box">
                        <img alt="SureCust" loading="lazy" src={copyFormidc} />
                      </div>
                    </div>
                  </div>
                  <ul className="Polaris-List Polaris-List--spacingLoose">
                    <li className="Polaris-List__Item">
                      <p className="Polaris-Text--root Polaris-Text--bodyMd">
                        Go to <b>Online Store &gt; Themes &gt; Customize: </b>
                      </p>
                    </li>
                  </ul>
                  <div className="Polaris-Box">
                    <div className="sureCustImage">
                      <div className="Polaris-Box">
                        <img alt="SureCust" loading="lazy" src={onlineStore}/>
                      </div>
                    </div>
                  </div>
                  <ul className="Polaris-List Polaris-List--spacingLoose">
                    <li className="Polaris-List__Item">
                      <p className="Polaris-Text--root Polaris-Text--bodyMd">
                        Now you need to go to <b>Contact</b> page <b>Template</b>
                      </p>
                    </li>
                  </ul>
                  <div className="Polaris-Box">
                    <div className="sureCustImage">
                      <div className="Polaris-Box">
                        <img alt="SureCust" loading="lazy" src={contactPageTemplate}/>
                      </div>
                    </div>
                  </div>
                  <ul className="Polaris-List Polaris-List--spacingLoose">
                    <li className="Polaris-List__Item">
                      <p className="Polaris-Text--root Polaris-Text--bodyMd">
                        Now you need to click on <b>Add Section</b> in <b>Template</b>
                      </p>
                    </li>
                  </ul>
                  <div className="Polaris-Box">
                    <div className="sureCustImage">
                      <div className="Polaris-Box">
                        <img alt="SureCust" loading="lazy" src={contactAddSection} />
                      </div>
                    </div>
                  </div>
                  <ul className="Polaris-List Polaris-List--spacingLoose">
                    <li className="Polaris-List__Item">
                      <p className="Polaris-Text--root Polaris-Text--bodyMd">
                        Select <b>Apps &gt; SureCust Custom Form</b>
                      </p>
                    </li>
                  </ul>
                  <div className="Polaris-Box">
                    <div className="sureCustImage">
                      <div className="Polaris-Box">
                        <img alt="SureCust" loading="lazy" src={sucreCustcustomForm} />
                      </div>
                    </div>
                  </div>
                  <ul className="Polaris-List Polaris-List--spacingLoose">
                    <li className="Polaris-List__Item">
                      <p className="Polaris-Text--root Polaris-Text--bodyMd">
                        Now you need to click on <b>Custom Form</b>
                      </p>
                    </li>
                  </ul>
                  <div className="Polaris-Box">
                    <div className="sureCustImage">
                      <div className="Polaris-Box">
                        <img alt="SureCust" loading="lazy" src={clickCustomForm} />
                      </div>
                    </div>
                  </div>
                  <ul className="Polaris-List Polaris-List--spacingLoose">
                    <li className="Polaris-List__Item">
                      <p className="Polaris-Text--root Polaris-Text--bodyMd">
                        Here you need to add <b>Form ID</b> that you copy in previous steps.
                      </p>
                    </li>
                  </ul>
                  <div className="Polaris-Box">
                    <div className="sureCustImage">
                      <div className="Polaris-Box">
                        <img alt="SureCust" loading="lazy" src={copyCustomFormid} />
                      </div>
                    </div>
                  </div>
                  <ul className="Polaris-List Polaris-List--spacingLoose">
                    <li className="Polaris-List__Item">
                      <p className="Polaris-Text--root Polaris-Text--bodyMd">
                        Finally, you can <b>disable your old form</b> and save customization.
                      </p>
                    </li>
                  </ul>
                  <div className="Polaris-Box">
                    <div className="sureCustImage">
                      <div className="Polaris-Box">
                        <img alt="SureCust" loading="lazy" src={disableContactform} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Accordion>
            <Accordion title="Set up the #Custom Product Enquiry form.">
              <div className='accordion-item-box-inner'>
                <div className="Polaris-Box">
                  <ul className="Polaris-List Polaris-List--spacingLoose">
                    <li className="Polaris-List__Item">
                      <p className="Polaris-Text--root Polaris-Text--bodyMd">
                        Go to: <b>SureCust App &gt; Custom forms &gt; </b> and click on <b>Create form</b>
                      </p>
                    </li>
                  </ul>
                  <div className="Polaris-Box">
                    <div className="sureCustImage">
                      <div className="Polaris-Box">
                        <img alt="SureCust" loading="lazy" src={createForm} />
                      </div>
                    </div>
                  </div>
                  <ul className="Polaris-List Polaris-List--spacingLoose">
                    <li className="Polaris-List__Item">
                      <p className="Polaris-Text--root Polaris-Text--bodyMd">
                        Now create <b>Product enquiry form</b>
                      </p>
                    </li>
                  </ul>
                  <div className="Polaris-Box">
                    <div className="sureCustImage">
                      <div className="Polaris-Box">
                        <img alt="SureCust" loading="lazy" src={createProductForm} />
                      </div>
                    </div>
                  </div>
                  <ul className="Polaris-List Polaris-List--spacingLoose">
                    <li className="Polaris-List__Item">
                      <p className="Polaris-Text--root Polaris-Text--bodyMd">
                        After creating custom form click on <b>Save</b>
                      </p>
                    </li>
                  </ul>
                  <div className="Polaris-Box">
                    <div className="sureCustImage">
                      <div className="Polaris-Box">
                        <img alt="SureCust" loading="lazy" src={saveProductForm} />
                      </div>
                    </div>
                  </div>
                  <ul className="Polaris-List Polaris-List--spacingLoose">
                    <li className="Polaris-List__Item">
                      <p className="Polaris-Text--root Polaris-Text--bodyMd">
                        After save you need to copy the <b>Form ID.</b>
                      </p>
                    </li>
                  </ul>
                  <div className="Polaris-Box">
                    <div className="sureCustImage">
                      <div className="Polaris-Box">
                        <img alt="SureCust" loading="lazy" src={copyProductForm} />
                      </div>
                    </div>
                  </div>
                  <ul className="Polaris-List Polaris-List--spacingLoose">
                    <li className="Polaris-List__Item">
                      <p className="Polaris-Text--root Polaris-Text--bodyMd">
                        Go to <b>Online Store &gt; Themes &gt; Customize: </b>
                      </p>
                    </li>
                  </ul>
                  <div className="Polaris-Box">
                    <div className="sureCustImage">
                      <div className="Polaris-Box">
                        <img alt="SureCust" loading="lazy" src={onlineStore}/>
                      </div>
                    </div>
                  </div>
                  <ul className="Polaris-List Polaris-List--spacingLoose">
                    <li className="Polaris-List__Item">
                      <p className="Polaris-Text--root Polaris-Text--bodyMd">
                        Now you need to go to <b>Product</b> page <b>Template</b>
                      </p>
                    </li>
                  </ul>
                  <div className="Polaris-Box">
                    <div className="sureCustImage">
                      <div className="Polaris-Box">
                        <img alt="SureCust" loading="lazy" src={productPageTemplate}/>
                      </div>
                    </div>
                  </div>
                  <ul className="Polaris-List Polaris-List--spacingLoose">
                    <li className="Polaris-List__Item">
                      <p className="Polaris-Text--root Polaris-Text--bodyMd">
                        Now you need to click on <b>Add Section</b> in <b>Template</b>
                      </p>
                    </li>
                  </ul>
                  <div className="Polaris-Box">
                    <div className="sureCustImage">
                      <div className="Polaris-Box">
                        <img alt="SureCust" loading="lazy" src={productAddSection} />
                      </div>
                    </div>
                  </div>
                  <ul className="Polaris-List Polaris-List--spacingLoose">
                    <li className="Polaris-List__Item">
                      <p className="Polaris-Text--root Polaris-Text--bodyMd">
                        Select <b>Apps &gt; SureCust Custom Form</b>
                      </p>
                    </li>
                  </ul>
                  <div className="Polaris-Box">
                    <div className="sureCustImage">
                      <div className="Polaris-Box">
                        <img alt="SureCust" loading="lazy" src={productCustomForm} />
                      </div>
                    </div>
                  </div>
                  <ul className="Polaris-List Polaris-List--spacingLoose">
                    <li className="Polaris-List__Item">
                      <p className="Polaris-Text--root Polaris-Text--bodyMd">
                        Now you need to click on <b>Custom Form</b>
                      </p>
                    </li>
                  </ul>
                  <div className="Polaris-Box">
                    <div className="sureCustImage">
                      <div className="Polaris-Box">
                        <img alt="SureCust" loading="lazy" src={productClickform} />
                      </div>
                    </div>
                  </div>
                  <ul className="Polaris-List Polaris-List--spacingLoose">
                    <li className="Polaris-List__Item">
                      <p className="Polaris-Text--root Polaris-Text--bodyMd">
                        Here you need to add <b>Form ID</b> that you copy in previous steps.
                      </p>
                    </li>
                  </ul>
                  <div className="Polaris-Box">
                    <div className="sureCustImage">
                      <div className="Polaris-Box">
                        <img alt="SureCust" loading="lazy" src={copyPasteProduct} />
                      </div>
                    </div>
                  </div>
                  <ul className="Polaris-List Polaris-List--spacingLoose">
                    <li className="Polaris-List__Item">
                      <p className="Polaris-Text--root Polaris-Text--bodyMd">
                        Finally, you can Save customization.
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </Accordion>
            <Accordion title="Set up the #Custom POS Customer form.">
              <div className='accordion-item-box-inner'>
                <div className="Polaris-Box">
                  <ul className="Polaris-List Polaris-List--spacingLoose">
                    <li className="Polaris-List__Item">
                      <p className="Polaris-Text--root Polaris-Text--bodyMd">
                        Go to: <b>SureCust App &gt; Custom forms &gt; </b> and click on <b>Create form</b>
                      </p>
                    </li>
                  </ul>
                  <div className="Polaris-Box">
                    <div className="sureCustImage">
                      <div className="Polaris-Box">
                        <img alt="SureCust" loading="lazy" src={createForm} />
                      </div>
                    </div>
                  </div>
                  <ul className="Polaris-List Polaris-List--spacingLoose">
                    <li className="Polaris-List__Item">
                      <p className="Polaris-Text--root Polaris-Text--bodyMd">
                        Now create <b>POS Customer form</b>
                      </p>
                    </li>
                  </ul>
                  <div className="Polaris-Box">
                    <div className="sureCustImage">
                      <div className="Polaris-Box">
                        <img alt="SureCust" loading="lazy" src={posCreateForm} />
                      </div>
                    </div>
                  </div>
                  <ul className="Polaris-List Polaris-List--spacingLoose">
                    <li className="Polaris-List__Item">
                      <p className="Polaris-Text--root Polaris-Text--bodyMd">
                        After creating custom form click on <b>Save</b>
                      </p>
                    </li>
                  </ul>
                  <div className="Polaris-Box">
                    <div className="sureCustImage">
                      <div className="Polaris-Box">
                        <img alt="SureCust" loading="lazy" src={savePosForm} />
                      </div>
                    </div>
                  </div>
                  <ul className="Polaris-List Polaris-List--spacingLoose">
                    <li className="Polaris-List__Item">
                      <p className="Polaris-Text--root Polaris-Text--bodyMd">
                        Now open <b>Shopify POS Software/Application</b> and click on <b>Add tile</b>
                      </p>
                    </li>
                  </ul>
                  <div className="Polaris-Box">
                    <div className="sureCustImage">
                      <div className="Polaris-Box">
                        <img alt="SureCust" loading="lazy" src={openPos} width="320"/>
                      </div>
                    </div>
                  </div>
                  <ul className="Polaris-List Polaris-List--spacingLoose">
                    <li className="Polaris-List__Item">
                      <p className="Polaris-Text--root Polaris-Text--bodyMd">
                        Now click on <b>App</b>
                      </p>
                    </li>
                  </ul>
                  <div className="Polaris-Box">
                    <div className="sureCustImage">
                      <div className="Polaris-Box">
                        <img alt="SureCust" loading="lazy" src={clickOnapp} width="320"/>
                      </div>
                    </div>
                  </div>
                  <ul className="Polaris-List Polaris-List--spacingLoose">
                    <li className="Polaris-List__Item">
                      <p className="Polaris-Text--root Polaris-Text--bodyMd">
                        Now select <b>SureCust</b>
                      </p>
                    </li>
                  </ul>
                  <div className="Polaris-Box">
                    <div className="sureCustImage">
                      <div className="Polaris-Box">
                        <img alt="SureCust" loading="lazy" src={selectSurecust} width="320"/>
                      </div>
                    </div>
                  </div>
                  <ul className="Polaris-List Polaris-List--spacingLoose">
                    <li className="Polaris-List__Item">
                      <p className="Polaris-Text--root Polaris-Text--bodyMd">
                        Now click on <b>Add</b>
                      </p>
                    </li>
                  </ul>
                  <div className="Polaris-Box">
                    <div className="sureCustImage">
                      <div className="Polaris-Box">
                        <img alt="SureCust" loading="lazy" src={addWebsite} width="320"/>
                      </div>
                    </div>
                  </div>
                  <ul className="Polaris-List Polaris-List--spacingLoose">
                    <li className="Polaris-List__Item">
                      <p className="Polaris-Text--root Polaris-Text--bodyMd">
                        Now you can Save
                      </p>
                    </li>
                  </ul>
                  <div className="Polaris-Box">
                    <div className="sureCustImage">
                      <div className="Polaris-Box">
                        <img alt="SureCust" loading="lazy" src={saveWebsiteTile} width="320"/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Accordion>
           </div>
        </div>
      </div>
    </section>
  );
};

export default ApproveCustomer;